.event-dummy{
    margin-top: 8%;
    margin-bottom: 15px;
   
}

.dummy-tittle{
    font-size: 20px;
}

.dummy-text{
    font-size: 16px;
}

@media (max-width: 425px){
    .dummy-text {
        font-size: 8px;
        padding-inline: 5%;
        font-family: 'Poppins', sans-serif;
        font-weight: 200;
    }
    .event-dummy{
        margin-top: 23%;
    }

     .dummy-tittle{
    font-size: 12px;
    }
}
