footer {
  background: var(--bs-white);
  padding: 20px;
  text-align: center;
  position: sticky;


  }
  .footer-logo {
    /* color: rgb(172, 168, 168);
    margin-left: -110px;
    margin-top: -84px;
    width: 274px; */

    color: rgb(172, 168, 168);
    width: 250px;

}
.logo-desc{
  /* margin-left: -554px;
  margin-top: -100px;
  color: black;
  font-size: 18px; */

 
  color: black;
  font-size: 18px;
}

.copyright {
  /* color: black;
  text-decoration: none !important;
  cursor: pointer;
  margin-left: -657px;
  margin-top: -8px;
  font-size: 15px; */

  color: black;
  text-decoration: none !important;
  cursor: pointer;
  font-size: 15px;
  text-align: left;


}
  
.mail-footer {
  /* color: black;
  margin-top: -45px;
  margin-left: -435px;
  font-size: 18px */


  color: black;
  font-size: 18px
}

  /* .mr-auto{
     margin-left: -659px;
    margin-top: -12px; 
  } */

  @media(max-width: 790px){
    .footer-logo {
      /* color: rgb(172, 168, 168);
      margin-left: -110px;
      margin-top: -84px;
      width: 274px; */
  
      color: rgb(172, 168, 168);
      width: 200px;
  
  }
  }


  @media(max-width: 767px){
    .copyright {
      /* color: black;
      text-decoration: none !important;
      cursor: pointer;
      margin-left: -657px;
      margin-top: -8px;
      font-size: 15px; */
    
      color: black;
      text-decoration: none !important;
      cursor: pointer;
      font-size: 15px;
      text-align: center;
    
    
    }
  }

  @media(max-width:500px){
    footer {
      /* background: #000; */
      padding: 15px;
      text-align: center;
      margin-top: -1%;
      position: sticky;
  
    }
    footer p {
      color: rgb(172, 168, 168) !important;
      text-decoration: none !important;
      cursor: pointer;
      font-size: 10px;
    }

    .footer-logo {
      /* color: rgb(172, 168, 168);
      margin-left: -110px;
      margin-top: -84px;
      width: 274px; */
  
      color: rgb(172, 168, 168);
      width: 150px;
    }

    .logo-desc{
      /* margin-left: -554px;
      margin-top: -100px;
      color: black;
      font-size: 18px; */
    
     
      color: gray;
      font-size: 15px;
    }

    .mail-footer {
      /* color: black;
      margin-top: -45px;
      margin-left: -435px;
      font-size: 18px */
    
    
      color: gray;
      font-size: 12px

    }


    .copyright {
      /* color: black;
      text-decoration: none !important;
      cursor: pointer;
      margin-left: -657px;
      margin-top: -8px;
      font-size: 15px; */
    
      color: black;
      text-decoration: none !important;
      cursor: pointer;
      font-size: 14px;
      text-align: center;
    
    
    }

  }



  