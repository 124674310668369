@import url(//db.onlinewebfonts.com/c/8c583b80ac9e392caea26d76a803b665?family=ITC+Bradley+Hand);


.hero-carousel {
    margin-top: 0%;
}

.carousel-title{
    font-family: "ITC Bradley Hand";
}

.carousel-text3{

    font-size: 28px;
    font-weight: 500;
    font-family: "ITC Bradley Hand";

    text-transform: capitalize;
    color: #ffffff;
}
.Carousel-Caption3{
    position: absolute;
    right: 10%;
    bottom: 28% !important;
    
    text-align: justify !important;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center;
}
.carousel-text {
    text-align: center;
    font-size: 26px;
    font-weight: 800;
    /*font-family: "ITC Bradley Hand";*/
    text-transform: capitalize;
    color: #ffffff;

}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 45%;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center;
}

.carousel-control-next {
    /* position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 15%;
    margin-inline: 0%;
    padding: 0;
    color: #0a0909;
    background-color: black;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease; */

    /**********************************/

    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    width: 15%; 
    margin-inline: 0%;
    margin-left: 0%;
    padding: 0;
    color: #0a0909;
    background-color: black;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease;
}

.carousel-control-prev {
    /* position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 15%;
    margin-inline: 0%;
    padding: 0;
    color: #0a0909;
    background-color: black;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease; */


    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    width: 15%;
    margin-inline: 0%;
    padding: 0;
    color: #0a0909;
    background-color: black;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease;
}

.carousel-indicators [data-bs-target] {
    border-radius: 100%;
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 10px;
    height: 10px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
}




/* Medium devices (landscape tablets, 768px and up) */
@media all and (max-width: 768px) {
    .carousel-caption {
        position: absolute;
        right: 15%;
        bottom: 26%;
        left: 15%;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        color: #fff;
        text-align: center;
    }

    .carousel-text {
        text-align: center;
        font-size: 25px;
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
        text-transform: capitalize;
        color: #ffffff;
    }
}

@media all and (max-width: 425px) {
    
    .hero-carousel {
        margin-top: 14%;
    }

    .carousel-caption {
        position: absolute;
        right: 15%;
        bottom: 12%;
        left: 15%;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        color: #fff;
        text-align: center;
    }

    .carousel-text {
        text-align: center;
        font-size: 8px;
        font-weight: 400;
        font-family: 'Poppins', sans-serif;
        text-transform: capitalize;
        color: #ffffff;
    }
    .carousel-indicators [data-bs-target] {
        border-radius: 100%;
        box-sizing: content-box;
        flex: 0 1 auto;
        width: 5px;
        height: 5px;
        padding: 0;
        margin-right: 3px;
        margin-left: 3px;
        margin-bottom: -16px;
        text-indent: -999px;
        cursor: pointer;
        background-color: #fff;
        background-clip: padding-box;
        border: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        opacity: .5;
        transition: opacity .6s ease;
    }
}

/* Extra small devices (phones, 600px and down) */
/* @media only screen and (max-width: 600px) {...} */

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {...} */



/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {...} */

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {...} */