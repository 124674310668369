@import url('http://fonts.cdnfonts.com/css/england-signature');



.aboutussImg{
    /* height: 88%;
    width: 190%;
    margin-top: 67px;
    margin-left: -42px; */
    /*-------------------*/
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    object-fit: fill;
    image-rendering: optimizeQuality;
    float: right;
    display: block;
}

.water{
/*  
    margin-top: 66px;
    margin-left: -314px; */
    /*----------------------*/
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    object-fit: fill;
    image-rendering: optimizeQuality;
    float: left;
    display: block;
    

}

.about-title {
    /* text-align: center;
    font-size: 30px;
    font-weight: 700;
    font-family: 'Times New Roman';
    margin-top: 5%;
    text-transform: capitalize;
    color: #504b4b;
    margin-bottom: 5%;
    margin-left: -184px; */

    /*---------------------------*/
    
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    font-family: 'Times New Roman';
    margin-top: 5%;
    text-transform: capitalize;
    color: #504b4b;
    margin-bottom: 5%;
    
}



.about-head {
    /* padding-inline: 10%;
    text-align: justify;
    margin-right: 17px;
    margin-top: 137px; */
    /*------------------------*/

    padding-inline: 10%;
    text-align: justify;
    margin-right: 17px;
    margin-top: 100px;
}

.about-main{
  
    /* text-align: center;
    font-size: 27px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
    color: #504b4b;
    margin-bottom: 3%;
    /* margin-left: 34px; */
  /*  width: 496px;
    margin-left: -189px; */
    /*-------------*/

    text-align: center;
    font-size: 27px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
    color: #504b4b;

}
.about-description{
   /* font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 100;
    text-align: revert;
    margin-top: 13px;
    width: 523px;
    margin-left: -204px; */
   
    /*----------------*/

    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 100;
    text-align: revert;
}

.about-btn-txt{
    color: #fff;
    text-decoration: none;
}

.about-home-btn{
    /* cursor: pointer;  commented originally*/

    /* margin-inline-start: 45.4%;
    margin-top: 0%;
    background: white;
    color: black;
    border: 2px solid black;
    font-size: 13px;
    border-radius: 4px;
    text-align: center;
    padding: 20px 26px;
    text-transform: uppercase;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.4s;
    margin-left: -190px;
    padding: 17px;
    font-family: 'Times New Roman'; */


    /*--------------------------------------------------------*/
    /* margin-inline-start: 40%; */
    /* margin-top: 0%; */
    background: white;
    color: black;
    border: 2px solid black;
    font-size: 13px;
    border-radius: 4px;
    text-align: center;
    /* padding: 20px 26px; */
    text-transform: uppercase;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.4s;
    max-width: 100%;
    max-height: 100%;
    font-family: 'Times New Roman';
    
    
}

.about-home-btn:hover {
    background: black;
    color: white;
}

@media all and (max-width: 786px){
    .about-title {
        padding: 3%;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        font-size: 20px;
        padding-top: 6%;
    }
    .about-head {
        padding-inline: 5%;
        text-align: justify;
    }
    .about-main {
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
        text-transform: capitalize;
        color: #504b4b;
        margin-bottom: 5%;
    }
    .about-description {
        font-size: 11px;
        /* padding-inline-start: 8%; */
        font-family: 'Poppins', sans-serif;
        font-weight: 200;
    }


   
    

   
}

@media all and (max-width: 425px){

    .about-home-btn{
        font-size: 8px;
    }

    .about-title {
        padding: 1%;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        font-size: 15px;
        padding-top: 2%;
    }
    .bg-image {
        background-repeat: no-repeat;
        background-size: cover;
        width: 70%;
        margin-left: 17%;
    }
    .about-head {
        padding-inline: 2%;
        margin-top: 15%;
        text-align: justify;
    }
    .about-main {
        text-align: center;
        font-size: 15px;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
        text-transform: capitalize;
        color: #504b4b;
        margin-bottom: 5%;
    }
    .about-description {
        font-size: 8px;
        padding-inline: 5%;
        font-family: 'Poppins', sans-serif;
        font-weight: 200;
        
    }
}